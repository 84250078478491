export default async function postApiServiceRestartService(
  serviceName: 'AgenteCliSiTef'
) {
  return fetch(`http://localhost:5000/api/service/restart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ serviceName }),
  });
}
