import { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';

import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';

import useProtectedByAuth from '@giro-pdv-whitelabel/hooks/useProtectedByAuth.hook';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import useFetchHook from '@giro-pdv-whitelabel/hooks/useFetch.hook';

import getApiUserkeysService from '@giro/shared-services/pdv/getApiUserkeys.service';
import deleteApiUserkeysIdService from '@giro/shared-services/pdv/deleteApiUserkeysId.service';
import postApiUserkeysService from '@giro/shared-services/pdv/postApiUserkeys.service';

const Content = () => {
  const [modalCreate, setModalCreate] = useState(false);
  const [dataPostKey, setDataPostKey] = useState<any>(null);

  const {
    data: dataUserkeys,
    loading: loadingUserkeys,
    handleFetch: handleFetchUserkeys,
  } = useFetchHook(getApiUserkeysService, {
    requestOnMount: true,
  });

  const {
    loading: loadingDeleteUserkeys,
    handleFetch: handleFetchDeleteUserkeys,
  } = useFetchHook(deleteApiUserkeysIdService);

  useEffect(() => {
    if (modalCreate) {
      setDataPostKey(null);
    }
  }, [modalCreate]);

  const handleCloseDialogNew = () => {
    setModalCreate(false);
  };

  const dialogDetails = (
    <Dialog
      open={modalCreate}
      scroll="body"
      onClose={() => {
        handleCloseDialogNew();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {true && (
        <Box display="flex" flexDirection="column" p={2} gap={1.5} width={500}>
          {!dataPostKey && (
            <Formik
              initialValues={{ name: '' }}
              validationSchema={yup.object({
                name: yup.string().required('Campo obrigatório'),
              })}
              validateOnMount
              onSubmit={async (values) => {
                const [success, data] = await postApiUserkeysService(
                  values as any
                );

                if (success) {
                  handleFetchUserkeys();
                }

                setDataPostKey(data);

                return success;
              }}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap={1.5}>
                    <FieldLabelFormikComponent
                      size="small"
                      name="name"
                      label="Nome"
                    />

                    <Box display="flex" flexDirection="row" gap={1.5}>
                      <Box width="100%" color="grey.700">
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            handleCloseDialogNew();
                          }}
                          color="inherit"
                          fullWidth
                        >
                          Fechar
                        </Button>
                      </Box>
                      <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={Object.keys(errors).length > 0}
                      >
                        Criar
                      </LoadingButton>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          )}

          {dataPostKey && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap={1.5}
              alignItems="center"
            >
              <Typography variant="subtitle1" fontWeight={600}>
                Chave gerada
              </Typography>
              <Typography align="center" variant="trasso_body_small">
                Antes de fechar essa janela, copie a chave abaixo e guarde em
                local seguro. Essa chave não será exibida novamente.
              </Typography>
              <Box
                p={1}
                border="1px solid transparent"
                borderColor="grey.300"
                width="100%"
                borderRadius={1}
                display="flex"
                flexDirection="column"
              >
                <Typography
                  variant="trasso_body_medium"
                  color="grey.900"
                  align="center"
                >
                  {dataPostKey?.apikey}
                </Typography>
              </Box>
              <Box width="100%" color="grey.700">
                <Button
                  onClick={() => {
                    handleCloseDialogNew();
                  }}
                  color="inherit"
                  fullWidth
                >
                  Fechar
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {false && (
        <Box
          width={500}
          height={500}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );

  return (
    <Box display="flex" gap={3} flexDirection="column">
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="subtitle1" fontWeight={600}>
            Geração de tokens
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => setModalCreate(true)}
          >
            Gerar
          </Button>
        </Box>

        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap={1.5}
          position="relative"
        >
          {dataUserkeys?.map((userkey) => (
            <Box
              p={1.5}
              bgcolor="grey.100"
              borderRadius={2}
              key={userkey?.id}
              border="1px solid transparent"
              borderColor="grey.300"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography variant="subtitle1">
                  <strong>Nome:</strong> {userkey?.name}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={async () => {
                    const confirm = window.confirm(
                      'Deseja excluir essa chave?'
                    );

                    if (!confirm) {
                      return;
                    }

                    const [success] = await handleFetchDeleteUserkeys(
                      userkey?.id
                    );

                    if (success) {
                      handleFetchUserkeys();
                    }
                  }}
                >
                  Excluir
                </Button>
              </Box>
            </Box>
          ))}

          {(loadingUserkeys || loadingDeleteUserkeys) && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              bgcolor="#ffffffa1"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>

      <Divider
        sx={{
          borderStyle: 'dashed',
        }}
      />

      <ul>
        <li>
          <Link
            href={'https://saas.sipe.registrocivil.org.br/documentation'}
            target="_blank"
            color="primary.dark"
            fontWeight="bold"
          >
            Swagger da API
            (https://saas.sipe.registrocivil.org.br/documentation)
          </Link>
        </li>
      </ul>

      {dialogDetails}
    </Box>
  );
};

const DocumentationModule = () => {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route
          exact
          path={ROUTES.DOCUMENTATION}
          component={() => <Content />}
        />
      </Switch>
    </AppLayout>
  );
};

export default DocumentationModule;
