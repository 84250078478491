import { flow } from 'lodash';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import getApiClientsService from '@giro/shared-services/pdv/getApiClients.service';
import postApiServiceRestartService from '@giro/shared-services/windowsServiceApi/postApiServiceRestart.service';

import toaster from '@giro/shared-store/toaster';

import pinpad from '../pinpad';

import currentStore from '.';

function* handleServiceGet() {
  const showToaster = flow(toaster.action.show, put);

  try {
    yield call(postApiServiceRestartService, 'AgenteCliSiTef');

    yield delay(1000);
  } catch (e) {
    console.log('Service not found');
  }

  yield put(currentStore.action.fetchStart());

  try {
    const [success, result] = yield call(getApiClientsService);

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(result));

    yield put(pinpad.action.servicePostIsPresent());
  } catch (e) {
    yield showToaster({
      message: 'Aconteceu um erro, tente novamente mais tarde',
      variant: 'error',
    });
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
